$mobile-padding: 1.25rem;
$tablet-padding: 1.875rem;
$desktop-padding: 5rem;

$mobile-width: 320px;
$tablet-width: 768px;
$tablet-width-h: 1024px;
$desktop-width: 1280px;
$desktop-width-1440: 1440px;
$desktop-width-1600: 1600px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
