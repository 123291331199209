$blue: #2b446a;

$red: #ff2211;
$dark-red: #ee2211;

$white: #ffffff;
$lightest-grey: #f5f5f5;
$light-grey: #eeeeee;
$grey: #c6c6c6;
$dark-grey: #808080;
$grey-f0: #f0f0f0;
$grey-e5: #e5e5e5;
$grey-c6: #c6c6c6;
$grey-cc: #ccc;
$grey-c0: #c0c0c0;
$red-f21: #f21;
$darkest-grey: #222222;

// RTH COLORS

$rth-light-grey: #f3f7fa;
$rth-light-grey-2: #dee2e5;
$rth-grey: #77848c;
$rth-grey-2: #c7d0d6;
$rth-blue: #1f5bff;
$rth-black: #0d151a;
$rth-red: #e5270e;
